.b,
.frameChild {
  position: absolute;
}
.frameChild {
  top: 0;
  left: 0;
  border-radius: 50%;
  background-color: var(--color-goldenrod-100);
  width: 100%;
  height: 100%;
}
.b {
  top: 1.125rem;
  left: 0.938rem;
  line-height: 2.063rem;
  display: inline-block;
  width: 2.688rem;
  height: 2.063rem;
  z-index: 1;
}
.ellipseParent {
  height: 4.25rem;
  flex: 1;
  position: relative;
}
.tuto4Inner {
  width: 12.813rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-47xl);
  box-sizing: border-box;
}
.titre {
  flex: 1;
  position: relative;
}
.titreWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-36xl) 0 var(--padding-34xl);
}
.dcouvrezNotreStartup {
  align-self: stretch;
  height: 4.313rem;
  position: relative;
  font-size: var(--font-size-base);
  display: inline-block;
  flex-shrink: 0;
}
.frameGroup {
  width: 12.813rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-12xs);
  color: var(--color-black);
}
.ctaChild {
  height: 1.688rem;
  width: 7.875rem;
  position: relative;
  border-radius: var(--br-base);
  background-color: var(--color-chocolate-100);
  display: none;
}
.plusDeDetails {
  flex: 1;
  position: relative;
  line-height: 1rem;
  display: inline-block;
  min-width: 7.063rem;
  z-index: 1;
}
.cta,
.ctaWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.cta {
  flex: 1;
  border-radius: var(--br-base);
  background-color: var(--color-chocolate-100);
  padding: var(--padding-8xs) 0 var(--padding-7xs) var(--padding-smi);
  white-space: nowrap;
}
.ctaWrapper {
  width: 12.75rem;
  padding: 0 var(--padding-20xl);
  box-sizing: border-box;
  font-size: var(--font-size-base);
  color: var(--color-white);
}
.tuto4Child {
  width: 16.5rem;
  height: 0.063rem;
  position: absolute;
  margin: 0 !important;
  top: 5.25rem;
  right: -10.375rem;
  object-fit: contain;
  z-index: 1;
}
.tuto4,
.tuto4Wrapper,
.tuto5Wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.tuto4 {
  align-self: stretch;
  padding: var(--padding-32xl) var(--padding-28xl) 0;
  position: relative;
  gap: var(--gap-lg);
}
.tuto4Wrapper,
.tuto5Wrapper {
  width: 21.625rem;
  padding: 0 var(--padding-4xl) 0 0;
  box-sizing: border-box;
  max-width: 100%;
}
.tuto5Wrapper {
  width: 20.188rem;
  justify-content: flex-end;
  padding: 0 0 var(--padding-12xs);
}
.b1,
.frameItem {
  position: absolute;
}
.frameItem {
  top: 0;
  left: 0;
  border-radius: 50%;
  background-color: var(--color-goldenrod-100);
  width: 100%;
  height: 100%;
}
.b1 {
  top: 1.125rem;
  left: 0.938rem;
  line-height: 2.063rem;
  display: inline-block;
  width: 2.688rem;
  height: 2.063rem;
  z-index: 1;
}
.ellipseGroup {
  height: 4.25rem;
  flex: 1;
  position: relative;
}
.tuto6Inner {
  width: 12.813rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-47xl);
  box-sizing: border-box;
}
.titre1 {
  flex: 1;
  position: relative;
}
.titreContainer {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-36xl) 0 var(--padding-34xl);
}
.dcouvrezNotreStartup1 {
  align-self: stretch;
  height: 4.313rem;
  position: relative;
  font-size: var(--font-size-base);
  display: inline-block;
  flex-shrink: 0;
}
.frameContainer {
  width: 12.813rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-12xs);
  color: var(--color-black);
}
.ctaItem {
  height: 1.688rem;
  width: 7.875rem;
  position: relative;
  border-radius: var(--br-base);
  background-color: var(--color-chocolate-100);
  display: none;
}
.plusDeDetails1 {
  flex: 1;
  position: relative;
  line-height: 1rem;
  display: inline-block;
  min-width: 7.063rem;
  z-index: 1;
}
.cta1,
.ctaContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.cta1 {
  flex: 1;
  border-radius: var(--br-base);
  background-color: var(--color-chocolate-100);
  padding: var(--padding-8xs) 0 var(--padding-7xs) var(--padding-smi);
  white-space: nowrap;
}
.ctaContainer {
  width: 12.75rem;
  padding: 0 var(--padding-20xl);
  box-sizing: border-box;
  font-size: var(--font-size-base);
  color: var(--color-white);
}
.tuto6Child {
  width: 16.063rem;
  height: 0.063rem;
  position: absolute;
  margin: 0 !important;
  top: 5.25rem;
  left: -9.812rem;
  border-top: 1px solid var(--color-navajowhite-100);
  box-sizing: border-box;
  z-index: 1;
}
.frameParent,
.tuto6 {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.tuto6 {
  width: 20.188rem;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--padding-32xl) var(--padding-28xl) 0;
  box-sizing: border-box;
  position: relative;
  gap: var(--gap-lg);
}
.frameParent {
  flex-direction: row;
  align-items: flex-end;
  gap: var(--gap-41xl);
  text-align: center;
  font-size: var(--font-size-17xl);
  color: var(--color-chocolate-100);
  font-family: var(--font-gentium-book-basic);
}
@media screen and (max-width: 1050px) {
  .frameParent {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1000px) {
  .b {
    line-height: 1.625rem;
  }
  .b,
  .titre {
    font-size: var(--font-size-10xl);
  }
  .b1 {
    line-height: 1.625rem;
  }
  .b1,
  .titre1 {
    font-size: var(--font-size-10xl);
  }
}
@media screen and (max-width: 450px) {
  .b {
    line-height: 1.25rem;
  }
  .b,
  .titre {
    font-size: var(--font-size-3xl);
  }
  .b1 {
    line-height: 1.25rem;
  }
  .b1,
  .titre1 {
    font-size: var(--font-size-3xl);
  }
}
