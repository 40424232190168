.b,
.esiau,
.projets1 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.projets1 {
  width: 9.188rem;
  position: relative;
}
.b,
.esiau {
  position: absolute;
}
.esiau {
  top: 53.813rem;
  left: 6rem;
  width: 7.875rem;
  height: 3.25rem;
  min-width: 7.875rem;
}
.b {
  top: 27.438rem;
  left: 8.125rem;
  font-size: 13.75rem;
  color: var(--color-chocolate-100);
  width: 3.563rem;
  height: 15.938rem;
}
.projet1 {
  height: 58.438rem;
  width: 19.875rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.b1,
.robotsmali {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}
.robotsmali {
  top: 53.688rem;
  left: 0;
  width: 16.438rem;
  height: 3.25rem;
}
.b1 {
  top: 27.438rem;
  left: 7.063rem;
  font-size: var(--font-size-181xl);
  color: var(--color-chocolate-100);
  width: 3.563rem;
  height: 14.5rem;
}
.projet2 {
  height: 58.438rem;
  width: 18.375rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.b2,
.parc {
  position: absolute;
  top: 20.75rem;
  left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7.875rem;
  height: 1.75rem;
}
.b2 {
  top: 28rem;
  left: 8.688rem;
  font-size: var(--font-size-181xl);
  color: var(--color-white);
  width: 3.563rem;
  height: 14.5rem;
}
.projet3 {
  height: 59rem;
  width: 20.813rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  background-image: url(/public/projet3@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  font-size: var(--font-size-5xl);
}
.b3,
.bangalor {
  position: absolute;
  top: 54.625rem;
  left: 4.438rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12.625rem;
  height: 3.25rem;
}
.b3 {
  top: 27.438rem;
  left: 7.375rem;
  font-size: var(--font-size-181xl);
  color: var(--color-chocolate-100);
  width: 3.563rem;
  height: 14.5rem;
}
.projet4 {
  height: 58.813rem;
  width: 21.438rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.projetContainer,
.projetContainerWrapper,
.projets {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.projetContainer {
  flex: 1;
  overflow-x: auto;
  flex-direction: row;
  gap: 1.418rem;
  max-width: 100%;
  text-align: center;
  font-size: var(--font-size-26xl);
  color: var(--color-black);
  font-family: var(--font-gentium-book-basic);
}
.projetContainerWrapper,
.projets {
  box-sizing: border-box;
}
.projetContainerWrapper {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-xs) 0 var(--padding-xl);
  max-width: 100%;
}
.projets {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  overflow-y: auto;
  flex-direction: column;
  padding: 0 var(--padding-7xl) var(--padding-mini);
  gap: var(--gap-6xl);
  line-height: normal;
  letter-spacing: normal;
  cursor: pointer;
  text-align: center;
  font-size: var(--font-size-15xl);
  color: var(--color-sandybrown);
  font-family: var(--font-gentium-book-basic);
}
@media screen and (max-width: 767px) {
  .projets1 {
    font-size: var(--font-size-8xl);
  }
  .esiau {
    font-size: var(--font-size-17xl);
  }
  .b {
    font-size: 5.5rem;
  }
  .robotsmali {
    font-size: var(--font-size-17xl);
  }
  .b1,
  .b2 {
    font-size: var(--font-size-61xl);
  }
  .bangalor {
    font-size: var(--font-size-17xl);
  }
  .b3 {
    font-size: var(--font-size-61xl);
  }
}
@media screen and (max-width: 450px) {
  .projets1 {
    font-size: 1.25rem;
  }
  .esiau {
    font-size: var(--font-size-8xl);
  }
  .b {
    font-size: 3.438rem;
  }
  .robotsmali {
    font-size: var(--font-size-8xl);
  }
  .b1 {
    font-size: var(--font-size-31xl);
  }
  .parc {
    font-size: var(--font-size-lgi);
  }
  .b2 {
    font-size: var(--font-size-31xl);
  }
  .bangalor {
    font-size: var(--font-size-8xl);
  }
  .b3 {
    font-size: var(--font-size-31xl);
  }
}
