.lienUtile {
  margin: 0;
  flex: 1;
  position: relative;
  font-size: inherit;
  line-height: 29px;
  font-weight: 700;
  font-family: inherit;
}
.lienUtileWrapper {
  width: 213px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 2px var(--padding-base);
  box-sizing: border-box;
  font-size: var(--font-size-21xl);
}
.siteMalicksEyes {
  align-self: stretch;
  position: relative;
}
.chaneYoutube {
  width: 185px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.siteMalicksEyesParent {
  width: 211px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.siteOfficielRobotsmali {
  position: relative;
  cursor: pointer;
}
.contactContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.laisseNousUnMessage {
  margin: 0;
  width: 265px;
  position: relative;
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
}
.message {
  height: 27px;
  flex: 1;
  min-width: 124px;
}
.ecrire {
  height: 11px;
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cta,
.messageParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.cta {
  width: 83px;
  border-radius: 4px;
  background-color: var(--color-white);
  overflow: hidden;
  flex-shrink: 0;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-4xs) var(--padding-5xs) 0;
  box-sizing: border-box;
  cursor: pointer;
}
.messageParent {
  flex: 1;
  justify-content: center;
  gap: 5px;
}
.frameWrapper {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 0 var(--padding-4xs);
  font-size: var(--font-size-base);
  color: var(--color-black);
}
.frameWrapper,
.laisseNousUnMessageParent,
.mediaContainerInner {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.laisseNousUnMessageParent {
  flex: 1;
  flex-direction: column;
  gap: 14px;
}
.mediaContainerInner {
  width: 298px;
  flex-direction: row;
  padding: 0 var(--padding-8xs);
  box-sizing: border-box;
}
.mediaIcon {
  position: absolute;
  top: 3px;
  left: -12px;
  width: 173px;
  height: 65px;
  object-fit: cover;
}
.suivezNous {
  margin: 0;
  position: absolute;
  top: 0;
  left: -7px;
  font-size: inherit;
  line-height: 17px;
  font-weight: 700;
  font-family: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 147px;
  height: 17px;
  z-index: 1;
}
.following {
  align-self: stretch;
  height: 55px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.contacts,
.followingArea,
.mediaContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.mediaContainer {
  align-self: stretch;
  gap: var(--gap-6xl);
}
.contacts,
.followingArea {
  width: 318px;
  padding: var(--padding-7xs) 0 0;
  box-sizing: border-box;
  text-align: center;
}
.contacts {
  width: 1440px;
  background-color: #b2a88f;
  max-width: 100%;
  overflow-y: auto;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--padding-28xl) 83px 82px 78px;
  line-height: normal;
  letter-spacing: normal;
  gap: var(--gap-xl);
  text-align: left;
  font-size: var(--font-size-5xl);
  color: var(--color-white);
  font-family: var(--font-gentium-book-basic);
}
@media screen and (max-width: 750px) {
  .lienUtile {
    font-size: 32px;
    line-height: 23px;
  }
  .contacts {
    flex-wrap: wrap;
    padding-left: var(--padding-20xl);
    padding-right: var(--padding-22xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .lienUtile {
    font-size: var(--font-size-5xl);
    line-height: 17px;
  }
  .chaneYoutube,
  .laisseNousUnMessage,
  .siteMalicksEyes,
  .siteOfficielRobotsmali {
    font-size: var(--font-size-lgi);
  }
  .messageParent {
    flex-wrap: wrap;
  }
  .suivezNous {
    font-size: var(--font-size-lgi);
    line-height: 14px;
  }
}
