.backgroundIcon {
  width: 100%;
  height: 100%;
  margin: 0 !important;
  top: calc(50% - 514px);
  right: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.a,
.backgroundIcon,
.frameChild {
  position: absolute;
}
.frameChild {
  top: 0;
  left: 0;
  border-radius: 50%;
  background-color: var(--color-tan);
  width: 100%;
  height: 100%;
  z-index: 1;
}
.a {
  margin: 0;
  top: 9px;
  left: 11px;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 21px;
  white-space: nowrap;
  z-index: 2;
}
.ellipseParent {
  align-self: stretch;
  height: 35.6px;
  position: relative;
}
.frameWrapper {
  width: 52px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-mini) 0 0;
  box-sizing: border-box;
}
.products,
.tutoriel {
  height: 73px;
  position: relative;
  display: inline-block;
  cursor: pointer;
  z-index: 1;
}
.tutoriel {
  width: 143.9px;
  flex-shrink: 0;
}
.products {
  align-self: stretch;
}
.productsLabel {
  width: 141.8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-7xs) 0 0;
  box-sizing: border-box;
}
.projects {
  height: 73px;
  width: 123.7px;
  position: relative;
  display: inline-block;
  flex-shrink: 0;
  cursor: pointer;
  z-index: 1;
}
.tutorielParent {
  width: 891px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 299px 0 0;
  box-sizing: border-box;
  gap: var(--gap-xl);
  max-width: 100%;
  font-size: var(--font-size-8xl);
  color: var(--color-black);
}
.frameItem {
  position: absolute;
  width: calc(100% - 4.9px);
  top: 0;
  right: 0;
  left: 4.9px;
  border-radius: 10px;
  background-color: var(--color-chocolate-100);
  height: 54.9px;
  z-index: 1;
}
.visitezMalicksEyes {
  position: absolute;
  top: 5.6px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 241px;
  height: 44px;
  white-space: nowrap;
  z-index: 2;
}
.rectangleParent {
  align-self: stretch;
  height: 54.9px;
  position: relative;
}
.frameContainer,
.frameParent,
.splashInner {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameContainer {
  width: 243.9px;
  flex-direction: column;
  padding: var(--padding-4xs) 0 0;
  box-sizing: border-box;
  font-size: var(--font-size-4xl);
  color: var(--color-white);
  font-family: var(--font-gentium-book-basic);
}
.frameParent,
.splashInner {
  flex-direction: row;
  max-width: 100%;
}
.frameParent {
  flex: 1;
  gap: 85px;
}
.splashInner {
  align-self: stretch;
  padding: 0 0 var(--padding-2xs) var(--padding-xl);
  box-sizing: border-box;
  font-family: var(--font-aksara-bali-galang);
}
.annah {
  margin: 0;
  flex: 1;
  position: relative;
  font-size: inherit;
  line-height: 147px;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  max-width: 100%;
  z-index: 1;
}
.annahWrapper {
  width: 522px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 34px;
  box-sizing: border-box;
  max-width: 100%;
}
.robotiquement,
.votre {
  margin: 0;
  position: relative;
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  flex-shrink: 0;
  debug_commit: 1de1738;
  z-index: 1;
}
.robotiquement {
  height: 52px;
  flex: 1;
  min-width: 269px;
  max-width: 100%;
}
.votre {
  align-self: stretch;
  height: 44.8px;
}
.votreWrapper {
  width: 139.3px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-9xs) 0 0;
  box-sizing: border-box;
  margin-left: -20px;
  font-size: var(--font-size-31xl);
  color: var(--color-tan);
}
.labelB,
.labelBWrapper,
.roboticallyLabel {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.roboticallyLabel {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 20px;
  margin-top: -6px;
  font-size: var(--font-size-33xl);
}
.labelB,
.labelBWrapper {
  justify-content: flex-start;
}
.labelB {
  flex: 1;
  flex-direction: column;
}
.labelBWrapper {
  width: 609.3px;
  flex-direction: row;
  padding: 0 38px;
  box-sizing: border-box;
  font-size: 142px;
}
.dcouvrezNotreStartup {
  align-self: stretch;
  position: relative;
  z-index: 1;
}
.goDownChild {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 39px;
  background-color: var(--color-chocolate-100);
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.decouvrez,
.nosActivits {
  margin: 0;
}
.decouvrezNosActivitsContainer {
  position: absolute;
  width: calc(100% - 28px);
  top: 5px;
  left: 14px;
  display: inline-block;
  height: 73px;
  z-index: 1;
}
.goDown {
  height: 78px;
  width: 361px;
  position: relative;
  max-width: 100%;
  z-index: 1;
}
.goDownWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl) 0 35px;
  box-sizing: border-box;
  max-width: 100%;
  text-align: center;
  font-size: var(--font-size-11xl);
  color: var(--color-white);
}
.dcouvrezNotreStartupDdieParent,
.splash {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.dcouvrezNotreStartupDdieParent {
  width: 580px;
  gap: 55px;
  text-align: left;
  font-size: var(--font-size-10xl);
  color: #64636d;
}
.splash {
  width: 1440px;
  background-color: var(--color-white);
  overflow-y: auto;
  padding: var(--padding-3xs) 24.1px 275px var(--padding-20xl);
  box-sizing: border-box;
  position: relative;
  gap: 97px;
  line-height: normal;
  letter-spacing: normal;
  cursor: pointer;
  text-align: center;
  font-size: var(--font-size-11xl);
  color: var(--color-chocolate-100);
  font-family: var(--font-gentium-book-basic);
}
@media screen and (max-width: 900px) {
  .a {
    font-size: var(--font-size-5xl);
  }
  .tutorielParent {
    display: none;
    padding-right: 149px;
    box-sizing: border-box;
  }
  .annah {
    font-size: 57px;
    line-height: 88px;
  }
  .robotiquement {
    font-size: var(--font-size-23xl);
  }
  .votre {
    font-size: var(--font-size-21xl);
  }
  .decouvrezNosActivitsContainer {
    font-size: var(--font-size-5xl);
  }
  .splash {
    gap: 48px;
  }
}
@media screen and (max-width: 700px) {
  .frameParent {
    gap: var(--gap-23xl);
  }
  .dcouvrezNotreStartupDdieParent {
    gap: 27px;
  }
}
@media screen and (max-width: 450px) {
  .a {
    font-size: var(--font-size-lg);
  }
  .tutorielParent {
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
  .frameParent {
    gap: 21px;
  }
  .annah {
    font-size: 35px;
    line-height: 59px;
  }
  .robotiquement {
    font-size: var(--font-size-12xl);
  }
  .votre {
    font-size: var(--font-size-11xl);
  }
  .votreWrapper {
    margin-left: 0;
  }
  .dcouvrezNotreStartup {
    font-size: var(--font-size-4xl);
  }
  .decouvrezNosActivitsContainer {
    font-size: var(--font-size-lg);
  }
  .splash {
    gap: var(--gap-5xl);
  }
}
