.lesTutoriels,
.trouvezIciNos {
  margin: 0;
  position: absolute;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
}
.lesTutoriels {
  top: 1.75rem;
  left: 26.375rem;
  font-size: inherit;
  width: 34.625rem;
  height: 5.25rem;
}
.trouvezIciNos {
  top: 7.813rem;
  left: 9.813rem;
  font-size: 3.5rem;
  color: var(--color-black);
  width: 70.375rem;
  height: 4.188rem;
}
.tutoContainer,
.tutoContainerWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.tutoContainer {
  flex-direction: column;
  gap: 3.312rem;
}
.tutoContainerWrapper {
  position: absolute;
  top: 14.75rem;
  left: 10.688rem;
  flex-direction: row;
  padding: 0 0 var(--padding-3xs);
  box-sizing: border-box;
}
.roboticsCompanyLogo {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 14px;
  background-color: var(--color-chocolate-100);
  width: 100%;
  height: 100%;
}
.voirPlus {
  position: absolute;
  top: 0.563rem;
  left: 4.813rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18rem;
  z-index: 1;
}
.cta {
  height: 3.313rem;
  width: 29.313rem;
  position: relative;
  max-width: 100%;
}
.ctaWrapper {
  position: absolute;
  top: 58.5rem;
  left: 31.5rem;
  width: 48.688rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  font-size: var(--font-size-17xl);
  color: var(--color-white);
}
.tuto {
  width: 100%;
  height: 64rem;
  position: relative;
  background-color: var(--color-white);
  overflow-y: auto;
  line-height: normal;
  letter-spacing: normal;
  cursor: pointer;
  text-align: center;
  font-size: 4.5rem;
  color: var(--color-chocolate-100);
  font-family: var(--font-gentium-book-basic);
}
@media screen and (max-width: 1050px) {
  .tuto {
    height: auto;
    min-height: 1024;
  }
}
@media screen and (max-width: 1000px) {
  .lesTutoriels {
    font-size: 3.625rem;
  }
  .trouvezIciNos {
    font-size: var(--font-size-26xl);
  }
  .voirPlus {
    font-size: var(--font-size-10xl);
  }
}
@media screen and (max-width: 450px) {
  .lesTutoriels {
    font-size: 2.688rem;
  }
  .trouvezIciNos {
    font-size: var(--font-size-15xl);
  }
  .voirPlus {
    font-size: var(--font-size-3xl);
  }
}
