.tlphone {
  align-self: stretch;
  position: relative;
  line-height: 2.25rem;
}
.iphone {
  flex: 1;
  position: relative;
}
.iphoneIcon,
.produit1Inner,
.tlphoneParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.iphoneIcon {
  width: 12.938rem;
  flex-direction: row;
  padding: 0 var(--padding-6xl);
  box-sizing: border-box;
  color: var(--color-black);
}
.produit1Inner,
.tlphoneParent {
  flex-direction: column;
}
.tlphoneParent {
  align-self: stretch;
  gap: var(--gap-5xs);
}
.produit1Inner {
  width: 14.313rem;
  padding: var(--padding-5xs) 0 0;
  box-sizing: border-box;
}
.phone1Icon {
  height: 15.25rem;
  width: 19.75rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
}
.produit1 {
  flex: 1;
  border-radius: var(--br-base);
  background-color: var(--color-slategray-200);
  border: 2px solid var(--color-slategray-100);
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: var(--padding-8xl) var(--padding-3xl) var(--padding-12xs)
    var(--padding-xl);
  min-width: 34.75rem;
  max-width: 100%;
  gap: var(--gap-xl);
}
.montage,
.pose {
  position: relative;
  display: inline-block;
}
.montage {
  margin-top: -2.375rem;
}
.pose {
  min-width: 4.875rem;
}
.arduinoHolder {
  flex-direction: row;
  padding: 0 var(--padding-4xl);
  margin-top: -0.375rem;
}
.arduinoHolder,
.description,
.descriptionWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.description {
  height: 5.813rem;
  flex: 1;
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-6xl) var(--padding-44xl) var(--padding-5xl)
    var(--padding-45xl);
  box-sizing: border-box;
  flex-shrink: 0;
  debug_commit: 1de1738;
}
.descriptionWrapper {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-5xs) 0 var(--padding-6xs);
}
.produit2,
.videoSupport1 {
  overflow: hidden;
  flex-shrink: 0;
}
.videoSupport1 {
  width: 14.25rem;
  height: 10.25rem;
  position: relative;
  object-fit: contain;
  debug_commit: 1de1738;
}
.produit2 {
  align-self: stretch;
  height: 15.813rem;
  border-radius: var(--br-base);
  background-color: var(--color-white);
  border: 2px solid var(--color-black);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-lgi) var(--padding-67xl) var(--padding-122xl);
  gap: var(--gap-6xs);
}
.produit1Parent,
.produit2Wrapper,
.produitsInner {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.produit2Wrapper {
  width: 29.125rem;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-xs) 0 0;
  box-sizing: border-box;
  min-width: 29.125rem;
  color: var(--color-black);
}
.produit1Parent,
.produitsInner {
  flex-direction: row;
}
.produit1Parent {
  flex: 1;
  justify-content: flex-start;
  gap: var(--gap-28xl);
}
.produitsInner {
  align-self: stretch;
  justify-content: flex-end;
  padding: 0 var(--padding-9xs) 0 0;
  box-sizing: border-box;
  text-align: center;
  font-size: var(--font-size-19xl);
  color: var(--color-gray);
  font-family: var(--font-gentium-book-basic);
}
@media screen and (max-width: 1200px) {
  .produit2Wrapper {
    flex: 1;
  }
  .produit1Parent {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1050px) {
  .tlphone {
    line-height: 1.813rem;
  }
  .iphone,
  .montage,
  .pose,
  .tlphone {
    font-size: var(--font-size-11xl);
  }
}
@media screen and (max-width: 750px) {
  .produit1 {
    flex-wrap: wrap;
  }
  .produit1,
  .produit2Wrapper {
    min-width: 100%;
  }
  .produit1Parent {
    gap: var(--gap-4xl);
  }
}
@media screen and (max-width: 450px) {
  .tlphone {
    line-height: 1.375rem;
  }
  .iphone,
  .montage,
  .pose,
  .tlphone {
    font-size: var(--font-size-4xl);
  }
  .description {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
  .produit2 {
    padding: var(--padding-xl) var(--padding-24xl) var(--padding-73xl);
    box-sizing: border-box;
  }
}
