@import url("https://fonts.googleapis.com/css2?family=Gentium+Book+Basic:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Aksara+Bali+Galang:wght@400&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-gentium-book-basic: "Gentium Book Basic";
  --font-aksara-bali-galang: "Aksara Bali Galang";

  /* font sizes */
  --font-size-17xl: 2.25rem;
  --font-size-3xl: 1.375rem;
  --font-size-10xl: 1.813rem;
  --font-size-base: 1rem;
  --font-size-15xl: 2.125rem;
  --font-size-26xl: 2.813rem;
  --font-size-33xl: 3.25rem;
  --font-size-12xl: 1.938rem;
  --font-size-23xl: 2.625rem;
  --font-size-5xl: 1.5rem;
  --font-size-lgi: 1.188rem;
  --font-size-181xl: 12.5rem;
  --font-size-31xl: 3.125rem;
  --font-size-61xl: 5rem;
  --font-size-8xl: 1.688rem;
  --font-size-19xl: 2.375rem;
  --font-size-4xl: 1.438rem;
  --font-size-11xl: 1.875rem;
  --font-size-lg: 1.125rem;
  --font-size-21xl: 2.5rem;

  /* Colors */
  --color-white: #fff;
  --color-chocolate-100: #d46c23;
  --color-navajowhite-100: #f3dda3;
  --color-black: #000;
  --color-goldenrod-100: #ffbd59;
  --color-sandybrown: #ed9455;
  --color-tan: #e6bb9d;
  --color-slategray-100: #696f7f;
  --color-slategray-200: rgba(115, 123, 142, 0.31);
  --color-gray: #161921;

  /* Gaps */
  --gap-41xl: 3.75rem;
  --gap-lg: 1.125rem;
  --gap-12xs: 0.062rem;
  --gap-23xl: 2.625rem;
  --gap-4xl: 1.437rem;
  --gap-xl: 1.25rem;
  --gap-6xl: 1.562rem;
  --gap-9xs: 0.25rem;
  --gap-5xl: 1.5rem;
  --gap-28xl: 2.937rem;
  --gap-6xs: 0.437rem;
  --gap-5xs: 0.5rem;

  /* Paddings */
  --padding-3xs: 0.625rem;
  --padding-32xl: 3.187rem;
  --padding-28xl: 2.937rem;
  --padding-20xl: 2.437rem;
  --padding-8xs: 0.312rem;
  --padding-7xs: 0.375rem;
  --padding-smi: 0.812rem;
  --padding-36xl: 3.437rem;
  --padding-34xl: 3.312rem;
  --padding-47xl: 4.125rem;
  --padding-12xs: 0.062rem;
  --padding-4xl: 1.437rem;
  --padding-lgi: 1.187rem;
  --padding-6xl: 1.562rem;
  --padding-5xl: 1.5rem;
  --padding-4xs: 0.562rem;
  --padding-2xs: 0.687rem;
  --padding-3xl: 1.375rem;
  --padding-lg: 1.125rem;
  --padding-xl: 1.25rem;
  --padding-7xl: 1.625rem;
  --padding-mini: 0.937rem;
  --padding-xs: 0.75rem;
  --padding-22xl: 2.562rem;
  --padding-9xs: 0.25rem;
  --padding-43xl: 3.875rem;
  --padding-6xs: 0.437rem;
  --padding-12xl: 1.937rem;
  --padding-44xl: 3.937rem;
  --padding-base: 1rem;
  --padding-5xs: 0.5rem;
  --padding-mid: 1.062rem;
  --padding-67xl: 5.375rem;
  --padding-122xl: 8.812rem;
  --padding-24xl: 2.687rem;
  --padding-73xl: 5.75rem;
  --padding-45xl: 4rem;
  --padding-8xl: 1.687rem;

  /* Border radiuses */
  --br-base: 16px;
}
