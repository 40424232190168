.arduino1Icon {
  position: absolute;
  top: 4.063rem;
  left: -1rem;
  width: 30.125rem;
  height: 18.188rem;
  overflow: hidden;
  object-fit: cover;
}
.electronique {
  width: 14.188rem;
  position: relative;
  line-height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.arduino {
  flex: 1;
  position: relative;
}
.headphoneIcon {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-12xl) 0 var(--padding-mid);
  color: #8dad6d;
}
.description {
  position: absolute;
  top: 1.313rem;
  left: 7.813rem;
  width: 14.563rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-mid) var(--padding-43xl);
  box-sizing: border-box;
  gap: 0.562rem;
  z-index: 1;
}
.informatique,
.produit3 {
  position: relative;
  flex-shrink: 0;
}
.produit3 {
  width: 29.125rem;
  height: 17.375rem;
  border-radius: var(--br-base);
  background-color: rgba(138, 173, 106, 0.16);
  border: 2px solid #91b072;
  box-sizing: border-box;
  overflow: hidden;
  max-width: 100%;
}
.informatique {
  flex: 1;
  debug_commit: 1de1738;
}
.bureauName {
  width: 16.125rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0.593rem 0 var(--padding-4xs);
  box-sizing: border-box;
}
.macBook {
  width: 10.938rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.mackBook1 {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;
  z-index: 1;
}
.laptopofficeContainer {
  align-self: stretch;
  height: 14.706rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: var(--padding-base) 3.281rem var(--padding-base) 3.25rem;
  box-sizing: border-box;
  position: relative;
  flex-shrink: 0;
  debug_commit: 1de1738;
}
.productBoxC,
.produit5,
.produit5Wrapper {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.produit5 {
  height: 17rem;
  flex: 1;
  border-radius: var(--br-base);
  background-color: #e9eaeb;
  border: 2px solid #d4d2d2;
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: column;
  align-items: flex-end;
  padding: var(--padding-lgi) 5.562rem 13.187rem var(--padding-44xl);
  gap: var(--gap-12xs);
}
.productBoxC,
.produit5Wrapper {
  align-items: flex-start;
}
.produit5Wrapper {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 0 var(--padding-3xl);
  box-sizing: border-box;
  color: var(--color-black);
}
.productBoxC {
  width: 30.5rem;
  flex-direction: column;
  gap: var(--gap-5xl);
  min-width: 30.5rem;
}
.informatique1 {
  align-self: stretch;
  position: relative;
}
.bureau {
  flex: 1;
  position: relative;
}
.bureauWrapper,
.informatiqueParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.bureauWrapper {
  align-self: stretch;
  flex-direction: row;
  padding: 0 3.125rem;
}
.informatiqueParent {
  width: 14.938rem;
  flex-direction: column;
  gap: var(--gap-9xs);
  flex-shrink: 0;
}
.laptopOffice1,
.produit4 {
  overflow: hidden;
  flex-shrink: 0;
}
.laptopOffice1 {
  margin-top: -8.25rem;
  height: 23.563rem;
  width: 30.75rem;
  position: relative;
  object-fit: cover;
  max-width: calc(100% - 317px);
}
.produit4 {
  align-self: stretch;
  height: 17.375rem;
  border-radius: var(--br-base);
  background-color: rgba(51, 59, 17, 0.63);
  border: 2px solid #f4ca3d;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-7xl) var(--padding-43xl) var(--padding-6xs);
  gap: 4.875rem;
  max-width: 100%;
}
.casque,
.divers {
  position: relative;
}
.divers {
  align-self: stretch;
}
.casque {
  flex: 1;
}
.casqueName,
.casqueWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.casqueWrapper {
  align-self: stretch;
  flex-direction: row;
  padding: 0 2.312rem 0 2.25rem;
}
.casqueName {
  width: 12.625rem;
  flex-direction: column;
  gap: var(--gap-9xs);
}
.headphones1Icon {
  margin-top: -6.125rem;
  height: 26.5rem;
  flex: 1;
  position: relative;
  max-width: calc(100% - 275px);
  overflow: hidden;
  object-fit: contain;
}
.produit6,
.produit6Wrapper {
  flex-direction: row;
  box-sizing: border-box;
}
.produit6 {
  height: 17.375rem;
  flex: 1;
  border-radius: var(--br-base);
  background-color: #f0f0f0;
  border: 2px solid #d9d9d7;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-6xl) 0 10.312rem var(--padding-lg);
  gap: 4.562rem;
  max-width: 100%;
}
.produit6Wrapper {
  align-self: stretch;
  padding: 0 0 0 var(--padding-9xs);
}
.productBoxCParent,
.produit4Parent,
.produit6Wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.produit4Parent {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-lg);
  min-width: 34.938rem;
  color: var(--color-black);
}
.productBoxCParent {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-6xl);
  text-align: center;
  font-size: var(--font-size-17xl);
  color: #8bae6b;
  font-family: var(--font-gentium-book-basic);
}
.ctaChild {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-base);
  background-color: var(--color-chocolate-100);
  width: 100%;
  height: 100%;
}
.voirDePlus {
  position: absolute;
  top: 0.25rem;
  left: 1.563rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 29.375rem;
  height: 2.625rem;
  z-index: 1;
}
.cta {
  height: 3.125rem;
  width: 34.75rem;
  position: relative;
  max-width: 100%;
}
.ctaName {
  width: 75.875rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  max-width: 100%;
}
.produits {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 2.5rem var(--padding-22xl) var(--padding-lgi) var(--padding-7xl);
  box-sizing: border-box;
  gap: 1.968rem;
  line-height: normal;
  letter-spacing: normal;
  cursor: pointer;
  text-align: center;
  font-size: var(--font-size-17xl);
  color: var(--color-white);
  font-family: var(--font-gentium-book-basic);
}
@media screen and (max-width: 1200px) {
  .productBoxC {
    flex: 1;
  }
  .productBoxCParent {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1050px) {
  .electronique {
    line-height: 1.625rem;
  }
  .arduino,
  .bureau,
  .electronique,
  .informatique,
  .informatique1,
  .macBook {
    font-size: var(--font-size-10xl);
  }
  .produit4 {
    gap: 2.438rem;
    padding-left: var(--padding-12xl);
    padding-right: var(--padding-12xl);
    box-sizing: border-box;
  }
  .casque,
  .divers {
    font-size: var(--font-size-10xl);
  }
  .produit6 {
    gap: 2.25rem;
  }
  .voirDePlus {
    font-size: var(--font-size-10xl);
  }
}
@media screen and (max-width: 750px) {
  .produit5 {
    padding-top: var(--padding-xl);
    padding-bottom: 8.563rem;
    box-sizing: border-box;
  }
  .productBoxC {
    min-width: 100%;
  }
  .produit4 {
    gap: 1.188rem;
  }
  .produit6 {
    padding-top: var(--padding-xl);
    padding-bottom: 6.688rem;
    box-sizing: border-box;
  }
  .produit4Parent {
    min-width: 100%;
  }
  .produits {
    gap: 1rem;
  }
}
@media screen and (max-width: 450px) {
  .electronique {
    line-height: 1.188rem;
  }
  .arduino,
  .electronique,
  .informatique,
  .macBook {
    font-size: var(--font-size-3xl);
  }
  .laptopofficeContainer {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
  .produit5 {
    padding-left: var(--padding-12xl);
    padding-right: 2.75rem;
    box-sizing: border-box;
  }
  .bureau,
  .casque,
  .divers,
  .informatique1 {
    font-size: var(--font-size-3xl);
  }
  .produit6 {
    gap: var(--gap-lg);
  }
  .voirDePlus {
    font-size: var(--font-size-3xl);
  }
}
