.voirLesPhotos {
  width: 172px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.frameChild,
.frameItem {
  align-self: stretch;
  position: relative;
  max-height: 100%;
  object-fit: cover;
  flex-shrink: 0;
  debug_commit: 1de1738;
}
.frameChild {
  margin-left: -320px;
  width: 280px;
  min-height: 422px;
}
.frameItem {
  width: 208px;
  min-height: 423px;
}
.rectangleParent {
  margin-left: -42px;
  width: 491px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 283px;
  box-sizing: border-box;
  gap: 40px;
  max-width: 123%;
  flex-shrink: 0;
}
.rightColumnIcon {
  height: 344px;
  width: 222px;
  position: relative;
  object-fit: cover;
}
.left,
.rightColumnWrapper {
  display: flex;
  align-items: flex-start;
}
.rightColumnWrapper {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl) 0 21px;
}
.left {
  position: absolute;
  top: 5px;
  left: 28px;
  filter: blur(4px);
  backdrop-filter: blur(4px);
  background-color: var(--color-tan);
  width: 429px;
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-smi) 14px 0;
  box-sizing: border-box;
  gap: 102.5px;
  max-width: 100%;
}
.voirLesPhotos1 {
  flex: 1;
  position: relative;
}
.voirLesPhotosWrapper {
  width: 304px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-47xl) var(--padding-smi);
  box-sizing: border-box;
}
.moreImagesContainer {
  height: 279px;
  width: 198px;
  position: relative;
  object-fit: cover;
}
.moreImagesContainerWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameInner {
  width: 194px;
  height: 266px;
  position: relative;
  object-fit: cover;
}
.frameParent,
.rightInner {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.frameParent {
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
}
.rightInner {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 0 0 var(--padding-4xs);
  box-sizing: border-box;
}
.moreImagesContainer1 {
  width: 238px;
  height: 362px;
  position: relative;
  object-fit: cover;
}
.right {
  position: absolute;
  top: 13px;
  left: 957px;
  filter: blur(4px);
  backdrop-filter: blur(4px);
  background-color: var(--color-tan);
  width: 450px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-2xs) 0 var(--padding-3xl) var(--padding-lg);
  box-sizing: border-box;
  gap: 15px;
  max-width: 100%;
}
.galerisParcDimages {
  width: 520px;
  height: 90px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  max-width: 100%;
}
.frameChild1,
.rectangleIcon {
  align-self: stretch;
  width: 292px;
  position: relative;
  max-height: 100%;
  object-fit: cover;
  min-height: 398px;
}
.frameChild1 {
  width: 224px;
}
.rectangleGroup,
.rightThumbnails {
  display: flex;
  align-items: flex-start;
}
.rectangleGroup {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  gap: var(--gap-4xl);
}
.rightThumbnails {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-xl);
  max-width: 100%;
}
.frameChild2 {
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.galleryOfImages,
.galleryOfImagesInner {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.galleryOfImagesInner {
  height: 478px;
  width: 297px;
  flex-direction: column;
  padding: var(--padding-4xs) 0 0;
  box-sizing: border-box;
  min-width: 297px;
}
.galleryOfImages {
  align-self: stretch;
  flex-direction: row;
  gap: 26px;
  max-width: 100%;
}
.frameChild3,
.frameChild4 {
  align-self: stretch;
  width: 292px;
  position: relative;
  max-height: 100%;
  object-fit: cover;
  min-height: 421px;
}
.frameChild4 {
  width: 224px;
}
.rectangleContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-4xl);
  min-width: 539px;
  max-width: 100%;
}
.frameChild5 {
  align-self: stretch;
  width: 280px;
  position: relative;
  max-height: 100%;
  object-fit: cover;
  min-height: 421px;
}
.frameGroup {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 43px;
  max-width: 100%;
}
.back,
.front {
  overflow: hidden;
  max-width: 100%;
  text-align: center;
  font-family: var(--font-gentium-book-basic);
}
.front {
  position: absolute;
  top: 0;
  left: 277px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25) inset, 0 4px 4px rgba(0, 0, 0, 0.25);
  background-color: var(--color-sandybrown);
  width: 905px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 28px var(--padding-lgi) var(--padding-6xl) var(--padding-5xl);
  box-sizing: border-box;
  gap: var(--gap-23xl);
  mix-blend-mode: luminosity;
  z-index: 1;
  font-size: var(--font-size-33xl);
  color: var(--color-white);
}
.back {
  height: 1024px;
  flex: 1;
  position: relative;
  border-radius: 9px;
  background-color: rgba(243, 221, 163, 0.44);
  font-size: var(--font-size-5xl);
  color: var(--color-chocolate-100);
}
.galeries {
  width: 1440px;
  background-color: var(--color-white);
  max-width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  line-height: normal;
  letter-spacing: normal;
  cursor: pointer;
}
@media screen and (max-width: 825px) {
  .frameChild,
  .frameItem {
    flex: 1;
  }
  .rectangleParent {
    flex-wrap: wrap;
    justify-content: center;
  }
  .galerisParcDimages {
    font-size: var(--font-size-23xl);
  }
  .frameChild2 {
    align-self: stretch;
    width: auto;
  }
  .galleryOfImagesInner {
    flex: 1;
  }
  .galleryOfImages {
    flex-wrap: wrap;
  }
  .frameChild5,
  .rectangleContainer {
    flex: 1;
  }
  .frameGroup {
    flex-wrap: wrap;
  }
  .back {
    height: auto;
    min-height: 1024;
  }
}
@media screen and (max-width: 675px) {
  .frameChild1,
  .rectangleIcon {
    flex: 1;
  }
  .rectangleGroup {
    flex-wrap: wrap;
  }
  .frameChild3,
  .frameChild4 {
    flex: 1;
  }
  .rectangleContainer {
    flex-wrap: wrap;
    min-width: 100%;
  }
}
@media screen and (max-width: 450px) {
  .voirLesPhotos,
  .voirLesPhotos1 {
    font-size: var(--font-size-lgi);
  }
  .galerisParcDimages {
    font-size: var(--font-size-12xl);
  }
  .rightThumbnails {
    min-width: 100%;
  }
}
