.b,
.frameChild {
  position: absolute;
}
.frameChild {
  top: 0;
  left: 0;
  border-radius: 50%;
  background-color: var(--color-goldenrod-100);
  width: 100%;
  height: 100%;
}
.b {
  top: 1.125rem;
  left: 0.938rem;
  line-height: 2.063rem;
  display: inline-block;
  width: 2.688rem;
  height: 2.063rem;
  z-index: 1;
}
.ellipseParent {
  height: 4.25rem;
  flex: 1;
  position: relative;
}
.tuto3Inner {
  width: 12.813rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-47xl);
  box-sizing: border-box;
}
.titre {
  flex: 1;
  position: relative;
}
.titreWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-36xl) 0 var(--padding-34xl);
}
.dcouvrezNotreStartup {
  align-self: stretch;
  height: 4.313rem;
  position: relative;
  font-size: var(--font-size-base);
  display: inline-block;
  flex-shrink: 0;
}
.frameParent {
  width: 12.813rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-12xs);
  color: var(--color-black);
}
.ctaChild {
  height: 1.688rem;
  width: 7.875rem;
  position: relative;
  border-radius: var(--br-base);
  background-color: var(--color-chocolate-100);
  display: none;
}
.plusDeDetails {
  flex: 1;
  position: relative;
  line-height: 1rem;
  display: inline-block;
  min-width: 7.063rem;
  z-index: 1;
}
.cta,
.ctaWrapper,
.tuto3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.cta {
  flex: 1;
  border-radius: var(--br-base);
  background-color: var(--color-chocolate-100);
  padding: var(--padding-8xs) 0 var(--padding-7xs) var(--padding-smi);
  white-space: nowrap;
}
.ctaWrapper,
.tuto3 {
  width: 12.75rem;
  padding: 0 var(--padding-20xl);
  box-sizing: border-box;
  font-size: var(--font-size-base);
  color: var(--color-white);
}
.tuto3 {
  width: 20.188rem;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: column;
  padding: var(--padding-32xl) var(--padding-28xl) 0;
  gap: var(--gap-lg);
  max-width: 100%;
  text-align: center;
  font-size: var(--font-size-17xl);
  color: var(--color-chocolate-100);
  font-family: var(--font-gentium-book-basic);
}
@media screen and (max-width: 1000px) {
  .b {
    line-height: 1.625rem;
  }
  .b,
  .titre {
    font-size: var(--font-size-10xl);
  }
}
@media screen and (max-width: 450px) {
  .b {
    line-height: 1.25rem;
  }
  .b,
  .titre {
    font-size: var(--font-size-3xl);
  }
}
